import { useEffect, useState } from "react";

import getGameLogs from "./adapter/ChaosDbApi";
import { getSeasonData, setSeasonData } from "./adapter/SeasonDbApi";
import getMainGraphData from "./presentation/MainGraphModel";
import MyResponsiveLine from "./component/graph/MyResponsiveLine";
import SeasonPicker from "./component/datepicker/SeasonPicker";
import GameDatePicker, {
  initialDateRange,
  dateToString,
} from "./component/datepicker/GameDatePicker";
import GameHistoryTable2 from "./component/table/GameHistoryTable2";
import { strObj } from "./logging/Logging";

import "./App.css";

function App() {
  const [gameData, setGameData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [dateRange, setDateRange] = useState(initialDateRange());
  const onRangeSelected = range => setDateRange(range);
  const onSeasonSelected = season => {
    console.log(season);
    const dr = {
      startDate: new Date(Date.parse(season.begin_date)),
      endDate: new Date(Date.parse(season.end_date)),
    };
    console.log(dr);
    setDateRange({
      startDate: new Date(Date.parse(season.begin_date)),
      endDate: new Date(Date.parse(season.end_date)),
    });
  };
  const onSeasonUpdated = season => {
    console.log(season);
    setSeasonData(season.seasonName, season.startDate, season.endDate);
  };

  async function updateGraph(startDate, endDate) {
    const gameLogs = await getGameLogs(startDate, endDate);
    setGameData(gameLogs);
    console.log(gameLogs);
    const newGraphData = await getMainGraphData(gameLogs);
    console.log(`newGraphData=${strObj(newGraphData)}`);
    setGraphData(newGraphData);
  }

  useEffect(() => {
    async function fetchSeasonData() {
      const seasonData = await getSeasonData();
      console.log(seasonData);
      setSeasons(seasonData);
    }
    fetchSeasonData();
  }, []);

  useEffect(() => {
    const startDate = dateToString(dateRange.startDate);
    const endDate = dateToString(dateRange.endDate);
    updateGraph(startDate, endDate);
  }, [dateRange]);

  console.log(seasons);

  return (
    <div className="App">
      <h1>Chaos Score</h1>
      <SeasonPicker
        seasonData={seasons}
        onSeasonSelected={onSeasonSelected}
        onSeasonUpdated={onSeasonUpdated}
      ></SeasonPicker>
      <GameDatePicker
        selectedDateRange={dateRange}
        onRangeSelected={onRangeSelected}
      ></GameDatePicker>
      <MyResponsiveLine data={graphData}></MyResponsiveLine>
      <GameHistoryTable2 data={gameData}></GameHistoryTable2>
    </div>
  );
}

export default App;
