import { strObj } from "../logging/Logging";

async function getMainGraphData(gameLogs) {
  const playerIds = new Set(gameLogs.map(data => data.id));
  const newGraphData = [];
  for (const playerId of playerIds) {
    const playerGameLogs = gameLogs.filter(data => data.id === playerId);
    const id = playerGameLogs[0].id;
    let accumulateScore = 0;
    let numOfWin = 0;
    let numOfLose = 0;
    const data = playerGameLogs.map(game => {
      accumulateScore += game.score;
      if (game.score === 1) {
        numOfWin++;
      } else {
        numOfLose++;
      }
      return {
        x: `${game.date}-${game.matchId + 1}`,
        y: accumulateScore,
        yLabel: { win: numOfWin, lose: numOfLose },
      };
    });
    const oDate = `${data[0].x.slice(0, 8)}-0`;
    data.unshift({ x: oDate, y: 0, yLabel: { win: 0, lose: 0 } });
    console.log(`playerId=${playerId} graphData=${strObj(data)}`);
    newGraphData.push({ id: id, data: data });
  }
  console.log(`player0 last y=${newGraphData[0].data.slice(-1)[0].y}`);
  console.log(`player1 ${strObj(newGraphData[1].data.slice(-1)[0])}`);
  console.log(`player1 last y=${newGraphData[1].data.slice(-1)[0].y}`);
  console.log(`result ${newGraphData}`);
  newGraphData.forEach(
    user => (user.data = user.data.sort((a, b) => a.x - b.x))
  );
  return newGraphData.sort(
    (a, b) => a.data.slice(-1)[0].y - b.data.slice(-1)[0].y
  );
}

export default getMainGraphData;
