import React, { useEffect, useState } from "react";

function SeasonForm({ selectedSeason, onAddSeason }) {
  const [seasonName, setSeasonName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (!selectedSeason) return;
    setSeasonName(selectedSeason.name);
    setStartDate(selectedSeason.begin_date);
    setEndDate(selectedSeason.end_date);
  }, [selectedSeason]);

  const handleSubmit = e => {
    e.preventDefault();
    if (seasonName && startDate && endDate) {
      onAddSeason({ seasonName, startDate, endDate });
      setSeasonName("");
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>시즌 이름</label>
        <input
          type="text"
          className="seasonName"
          value={seasonName}
          onChange={e => setSeasonName(e.target.value)}
        />
        <label>시작 날짜</label>
        <input
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <label>종료 날짜</label>
        <input
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <button type="submit">추가</button>
      </div>
    </form>
  );
}

export default SeasonForm;
