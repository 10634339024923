import { strObj } from "../logging/Logging";

const GAME_LOGS_KEY = "game_logs_key";
const GAME_LOGS_DATES_KEY = "game_logs_dates_key";

async function getGameLogs(startDate, endDate) {
  let savedGameLogs = JSON.parse(localStorage.getItem(GAME_LOGS_KEY));
  let savedDates = JSON.parse(localStorage.getItem(GAME_LOGS_DATES_KEY));
  if (
    savedDates != null &&
    savedDates.startDate === startDate &&
    savedDates.endDate === endDate &&
    savedGameLogs !== null
  ) {
    console.log("use saved game logs");
    return savedGameLogs;
  }
  console.log("start to fetch game logs");
  const gameLogs = [];
  const documents = await fetchGameLogs(startDate, endDate);
  for (const document of documents) {
    const date = document.date;
    const matches = document.data.matches;
    const parsedGameLog = await parseGameLog(date, matches);
    gameLogs.push(...parsedGameLog);
  }
  gameLogs.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    if (a.matchId < b.matchId) {
      return -1;
    }
    if (a.matchId > b.matchId) {
      return 1;
    }
    return 0;
  });
  console.log(`gameLogs=${strObj(gameLogs)}`);
  localStorage.setItem(GAME_LOGS_KEY, JSON.stringify(gameLogs));
  localStorage.setItem(
    GAME_LOGS_DATES_KEY,
    JSON.stringify({ startDate: startDate, endDate: endDate })
  );
  return gameLogs;
}

async function fetchGameLogs(startDate, endDate) {
  const url = `${process.env.REACT_APP_DB_URL}/collection`;
  // const url = "http://localhost:5000/collection";
  const header = {
    "Content-Type": "application/json",
    "Access-Control-Request-Headers": "*",
  };
  const payload = {
    collection: "games",
    database: "chaos",
    dataSource: "yul2ya",
    filter: { date: { $gte: startDate, $lte: endDate } },
  };
  const response = await fetch(url, {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  });
  const json = await response.json();
  const documents = await json.documents;
  return documents;
}

async function parseGameLog(date, matches) {
  const flattenGameLogs = [];
  let matchId = 0;
  for (const match of matches) {
    for (const player of match.scourge.players) {
      const gameLog = {
        id: player.id,
        hero: player.hero,
        team: "언데드",
        date: date,
        matchId: matchId,
        score: await getScore(match.scourge.result),
      };
      flattenGameLogs.unshift(gameLog);
    }
    for (const player of match.sentinel.players) {
      flattenGameLogs.unshift({
        id: player.id,
        hero: player.hero,
        team: "나엘",
        date: date,
        matchId: matchId,
        score: await getScore(match.sentinel.result),
      });
    }
    matchId++;
  }
  return flattenGameLogs;
}

async function getScore(result) {
  let score = 0;
  if (result === "승") {
    score = 1;
  } else if (result === "패") {
    score = -1;
  }
  return score;
}

export default getGameLogs;
