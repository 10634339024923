import { ResponsiveLine } from "@nivo/line";
import { strObj } from "../../logging/Logging";
import "./MyResponsiveLine.css";

function getYlabel(data, point) {
  return data
    .find(e => e.id === point.serieId)
    .data.find(e => e.x === point.data.x).yLabel;
}

const MyResponsiveLine = ({ data /* see data tab */ }) => {
  console.log(`MyResponsiveLine data=${strObj(data)}`);
  return (
    <div className="graphContainer">
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{
          type: "point",
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        sliceTooltip={({ slice }) => {
          slice.points.sort(function (a, b) {
            return b.data.y - a.data.y;
          });
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId} </strong>[{getYlabel(data, point).win}
                  -{getYlabel(data, point).lose}= {point.data.y}]
                </div>
              ))}
            </div>
          );
        }}
        yFormat=" >-.0f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickRotation: 35,
          tickValues: 1,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "score",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "dark2" }}
        pointSize={5}
        pointColor={{ theme: "background" }}
        pointBorderWidth={1}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default MyResponsiveLine;
