const baseUrl = `${process.env.REACT_APP_DB_URL}`;
// const baseUrl = "http://localhost:5000";

async function getSeasonData() {
  const url = `${baseUrl}/collection`;
  const header = {
    "Content-Type": "application/json",
    "Access-Control-Request-Headers": "*",
  };
  const payload = {
    collection: "seasons",
    database: "chaos",
    dataSource: "yul2ya",
  };
  const response = await fetch(url, {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  });
  const json = await response.json();
  const documents = await json.documents;
  return documents;
}

async function setSeasonData(seasonName, beginDate, endDate) {
  console.log(seasonName, beginDate, endDate);
  const url = `${baseUrl}/update_season`;
  const header = {
    "Content-Type": "application/json",
    "Access-Control-Request-Headers": "*",
  };
  const payload = {
    collection: "seasons",
    database: "chaos",
    dataSource: "yul2ya",
    filter: { name: seasonName },
    update: { name: seasonName, begin_date: beginDate, end_date: endDate },
    upsert: true,
  };
  console.log(payload);
  const response = await fetch(url, {
    method: "POST",
    headers: header,
    body: JSON.stringify(payload),
  });
  const json = await response.json();
  const documents = await json.documents;
  return documents;
}

export { getSeasonData, setSeasonData };
