import MaterialReactTable from "material-react-table";
import React, { useMemo } from "react";

export default function GameHistoryTable2({ data }) {
  const tableData = data;
  tableData.sort((a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    if (a.matchId > b.matchId) {
      return -1;
    }
    if (a.matchId < b.matchId) {
      return 1;
    }
    return 0;
  });
  tableData.map(row => {
    const score = tableData
      .filter(e => e.id === row.id && e.date <= row.date)
      .map(e => e.score)
      .reduce((acc, cur) => acc + cur, 0);
    const win = tableData
      .filter(e => e.id === row.id && e.date <= row.date && e.score > 0)
      .map(e => e.score)
      .reduce((acc, cur) => acc + cur, 0);
    const lose = tableData
      .filter(e => e.id === row.id && e.date <= row.date && e.score < 0)
      .map(e => e.score)
      .reduce((acc, cur) => acc + cur, 0);
    row.date = row.date.slice(2);
    row.team = row.team.slice(0, 2);
    row.lastScore = score;
    row.win = win;
    row.lose = lose;
    if (row.score > 0) {
      row.result = "승";
    } else {
      row.result = "패";
    }
    return row;
  });
  //simple column definitions pointing to flat data
  const ratio = window.screen.width / 389;
  //   const more = window.screen.width > window.screen.height ? 150 : 0;
  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        size: 35 * ratio,
      },
      {
        accessorKey: "matchId",
        header: "Match",
        size: 5 * ratio,
      },
      {
        accessorKey: "team",
        header: "Team",
        size: 25 * ratio,
      },
      {
        accessorKey: "id",
        header: "User",
        size: 50 * ratio,
      },
      {
        accessorKey: "hero",
        header: "Hero",
        size: 50 * ratio,
      },
      {
        accessorKey: "result",
        header: "Result",
        size: 5 * ratio,
      },
      {
        accessorKey: "lastScore",
        header: "Score",
        size: 10 * ratio,
      },
      {
        accessorKey: "win",
        header: "Wins",
        size: 10 * ratio,
      },
      {
        accessorKey: "lose",
        header: "Loses",
        size: 10 * ratio,
      },
    ],
    [ratio]
  );

  return (
    <div className="game-history-table-container" style={{ maxWidth: "100%" }}>
      <MaterialReactTable
        style={{ overflow: "auto" }}
        columns={columns}
        data={tableData}
        enableColumnResizing
        initialState={{ density: "compact" }}
        layoutMode="grid" //instead of the default "semantic" layout mode
        muiTableHeadCellProps={{
          sx: {
            flex: "0 0 auto",
            fontSize: "0.7rem",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            flex: "0 0 auto",
            fontSize: "0.6rem",
          },
        }}
        defaultColumn={{
          minSize: 3, //allow columns to get smaller than default
          maxSize: 42, //allow columns to get larger than default
          //   size: 35, //make columns wider by default
        }}
      />
    </div>
  );
}
