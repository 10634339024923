import React, { useEffect, useState } from "react";
import "./SeasonPicker.css";
import SeasonForm from "./SeasonForm";
import SeasonTable from "./SeasonTable";

function SeasonPicker({ seasonData, onSeasonSelected, onSeasonUpdated }) {
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);

  const handleAddSeason = season => {
    console.log(
      `${season.seasonName} (${season.startDate} ~ ${season.endDate}) is selected `
    );
    const newSeason = {
      name: season.seasonName,
      begin_date: season.startDate,
      end_date: season.endDate,
      _id: "new",
    };
    onSeasonUpdated(season);
    setSeasons([
      ...seasons.filter(season => season.name !== newSeason.name),
      newSeason,
    ]);
  };

  const handleRowClick = season => {
    setSelectedSeason(season);
    onSeasonSelected(season);
    // update datepicker
  };

  useEffect(() => {
    console.log(seasonData);
    setSeasons([...seasonData]);
  }, [seasonData]);

  return (
    <div className="SeasonPicker">
      <SeasonForm
        selectedSeason={selectedSeason}
        onAddSeason={handleAddSeason}
      />
      <SeasonTable seasons={seasons} onRowClick={handleRowClick} />
      {selectedSeason && (
        <div>
          <div className="selected-season">
            {selectedSeason.name} ({selectedSeason.begin_date} ~{" "}
            {selectedSeason.end_date})
          </div>
        </div>
      )}
    </div>
  );
}

export default SeasonPicker;
