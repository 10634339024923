import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { format, subDays } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import "./GameDatePicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

registerLocale("ko", ko);

const SELECTED_DATE_KEY = "selected date key";

export function initialDateRange() {
  let selectedDates = JSON.parse(localStorage.getItem(SELECTED_DATE_KEY));
  if (selectedDates !== null) {
    console.log(selectedDates);
    return {
      startDate: new Date(Date.parse(selectedDates.startDate)),
      endDate: new Date(Date.parse(selectedDates.endDate)),
    };
  }
  return {
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  };
}

export function dateToString(valueAsdate) {
  const ret = format(valueAsdate, "yyyyMMdd");
  console.log(ret);
  return ret;
}

const GameDatePicker = ({ selectedDateRange, onRangeSelected }) => {
  console.log(selectedDateRange);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const onButtonClick = () => {
    const selected_date = { startDate: startDate, endDate: endDate };
    console.log(startDate, endDate);
    localStorage.setItem(SELECTED_DATE_KEY, JSON.stringify(selected_date));
    onRangeSelected(selected_date);
  };

  useEffect(() => {
    setStartDate(selectedDateRange.startDate);
    setEndDate(selectedDateRange.endDate);
    console.log(startDate, endDate);
  }, [selectedDateRange.startDate, selectedDateRange.endDate, startDate, endDate]);

  return (
    <div className="date-picker-container">
      <DatePicker
        className="date-picker"
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        popperPlacement="auto"
        onChange={date => setStartDate(date)}
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        className="date-picker"
        fixedHeight
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        popperPlacement="auto"
        onChange={date => setEndDate(date)}
        selected={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
      <button
        className="date-picker date-picker-button"
        onClick={onButtonClick}
      >
        조회
      </button>
    </div>
  );
};

export default GameDatePicker;
