import React from "react";

function SeasonTable({ seasons, onRowClick }) {
  console.log(seasons);
  return (
    <table>
      <thead>
        <tr>
          <th>시즌 이름</th>
          <th>시작 날짜</th>
          <th>종료 날짜</th>
        </tr>
      </thead>
      <tbody>
        {seasons.map((season, index) => (
          <tr key={index} onClick={() => onRowClick(season)}>
            <td>{season.name}</td>
            <td>{season.begin_date}</td>
            <td>{season.end_date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SeasonTable;
